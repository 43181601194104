import axios from "@/config/http";
export const payServiceApi = {
    // 查询付费服务详情
    getServiceDetail(params) {
        return axios.get("/mall/payService", { params });
    },
    // 更新付费服务详情信息
    updateService(data){
        return axios.put("/mall/payService",data)
    },
    // 查询所有付费服务信息
    getAllService(){
        return axios.get("/mall/payService/getPayServiceInfo")
    }
}