<template>
  <a-modal
    title="编辑"
    :visible="config.visible"
    @cancel="handleCancel"
    width="900px"
    @ok="handleOk"
    ok-text="确定"
    cancel-text="取消"
  >
    <a-form-model
      ref="ruleForm"
      :model="payServiceForm"
      :rules="payServiceRules"
    >
      <div class="flex">
        <a-form-model-item label="会员类型" prop="serviceType">
          <a-input
            placeholder="请输入"
            v-model="serviceEnm[payServiceForm.serviceType]"
            :disabled="true"
          />
        </a-form-model-item>
        <a-form-model-item label="收费方式" prop="chargeMode">
          <a-input
            placeholder="请输入"
            v-model="payServiceForm.chargeMode"
            :disabled="true"
          />
        </a-form-model-item>
      </div>
      <div class="flex">
        <a-form-model-item label="套餐" v-if="payServiceForm.setMealInfo">
          <a-input
            class="package"
            placeholder="请输入"
            v-model="payServiceForm.setMealInfo[0]"
            
          />
          元/
          <a-input
            class="package"
            placeholder="请输入"
            v-model="payServiceForm.setMealInfo[1]"
           
          />
          <span v-if="payServiceForm.buyMode === '联系客服'">年</span>
          <span v-else>家</span>
        </a-form-model-item>
        <a-form-model-item label="购买方式" prop="buyMode">
          <a-input
            placeholder="请输入"
            v-model="payServiceForm.buyMode"
            :disabled="true"
          />
        </a-form-model-item>
      </div>
      <div class="flex">
        <a-form-model-item
          label="工作人员"
          prop="contact"
          v-if="payServiceForm.buyMode === '联系客服'"
        >
          <a-input placeholder="请输入" v-model="payServiceForm.contact" />
        </a-form-model-item>
        <a-form-model-item
          label="联系方式 "
          prop="phone"
          v-if="payServiceForm.buyMode === '联系客服'"
        >
          <a-input
            placeholder="请输入"
            v-model="payServiceForm.phone"
            class="connect"
          />
          <a-checkbox v-model="payServiceForm.ifWechatNumber"
            >微信同号</a-checkbox
          >
        </a-form-model-item>
      </div>
      <div class="serveice">
        <a-form-model-item label="服务内容" prop="serviceInfoList">
          <div
            class="content-box"
            v-for="(item, index) in payServiceForm.serviceInfoList"
            :key="index"
          >
            <a-textarea
              placeholder="请输入"
              v-model="item.content"
              class="textarea"
              :maxLength="200"
            />
            <div>
              <p @click="addContent(index)" class="add">
                <span class="add-plus"><a-icon type="plus-circle" /></span
                >添加一行
              </p>
              <p
                @click="delContent(index)"
                class="del"
                v-if="payServiceForm.serviceInfoList.length > 1"
              >
                <span class="add-plus"> <a-icon type="minus-circle" /></span
                >删除一行
              </p>
            </div>
          </div>
        </a-form-model-item>
      </div>
    </a-form-model>
  </a-modal>
</template>
<script>
import { payServiceRules } from "@/utils/rules.js";
import { payServiceApi } from "@/api/payService";
export default {
  props: {
    config: {
      type: Object,
      default() {
        return {
          visible: false,
        };
      },
    },
  },
  data() {
    return {
      payServiceRules,
      serviceEnm: { 1: "贸易端", 2: "游客端" },
      payServiceForm: {
        serviceType: null, //会员类型
        chargeMode: null,
        setMealInfo: null,
        buyMode: null,
        contact: null,
        phone: null,
        serviceInfoList: [],
      },
    };
  },
  methods: {
    handleCancel() {
      this.config.visible = false;
    },
    handleOk() {
      this.$refs.ruleForm.validate(async (res) => {
        if (res) {
          const payServiceUpdateDTO = {
            ...this.payServiceForm,
          };
          const result = await payServiceApi.updateService(payServiceUpdateDTO);
          if (result.success) {
            this.$message.success("保存成功");
            this.$parent.getBaseData()
            this.handleCancel();
          } else {
            this.$message.error(result.errorMsg);
            throw new Error(result.errorMsg);
          }
        }
      });
    },
    addContent() {
      this.payServiceForm.serviceInfoList.push({ content: "" });
    },
    delContent(i) {
      this.payServiceForm.serviceInfoList.splice(i, 1);
    },
    async getBaseData() {
      const res = await payServiceApi.getServiceDetail({
        id: this.config.data.id,
      });
      if (res.success) {
        this.payServiceForm = res.data;
        if (!this.payServiceForm.serviceInfoList.length) {
          this.payServiceForm.serviceInfoList = [{ content: "" }];
        }
      } else {
        this.$message.error(res.errorMsg);
        throw new Error(res.errorMsg);
      }
    },
  },
  mounted() {
    this.getBaseData();
  },
};
</script>
<style lang="scss" scoped>
.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /deep/.ant-form-item {
    width: 45%;
  }
}
.package {
  width: 150px;
}
.connect {
  width: 270px;
  margin-right: 25px;
}
.textarea {
  width: 737px;
  margin-right: 25px;
}
.content-box {
  display: flex;
  margin-bottom: 20px;
}
.add-plus {
  margin-right: 10px;
}
.add {
  color: #ff4600;
}
.del {
  color: #999999;
}
</style>
