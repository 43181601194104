<template>
  <div class="search-box top-container">
    <a-table
      :columns="columns"
      :pagination="false"
      :data-source="data"
      :scroll="{ y: 375 }"
      :loading="loading"
    >
      <template slot="serviceType" slot-scope="text, record">
        <div>{{ serviceEnm[record.serviceType] }}</div>
      </template>
      <template slot="setMealInfo" slot-scope="text, record">
        <div v-if="record.serviceType === 1">
          {{
            `${record.setMealInfo[0]}元/${record.setMealInfo[1]===1?'':record.setMealInfo[1]}年`
          }}
        </div>
        <div  v-if="record.serviceType === 2">  {{
            `${record.setMealInfo[0]}元/${record.setMealInfo[1]===1?'':record.setMealInfo[1]}家`
          }}</div>
          <div v-if="record.serviceType === 0">{{record.setMealInfo}}</div>
          <div v-if="record.serviceType === 3">{{record.setMealInfo}}</div>
      </template>

      <template slot="option" slot-scope="text, record">
        <span
          @click="editFactory(record)"
          style="color: rgb(255, 70, 0); cursor: pointer"
        >
          编辑
        </span>
      </template>
    </a-table>
    <editPayService v-if="config.visible" :config="config"/>
  </div>
</template>
<script>
const columns = [
  {
    title: "会员类型",
    dataIndex: "serviceType",
    key: "serviceType",
    ellipsis: true,
    slots: { title: "serviceType_1" },
    scopedSlots: { customRender: "serviceType" },
  },
  {
    title: "服务方式",
    dataIndex: "chargeMode",
    ellipsis: true,
    key: "chargeMode",
    scopedSlots: { customRender: "chargeMode" },
  },
  {
    title: "套餐",
    dataIndex: "setMealInfo",
    ellipsis: true,
    key: "setMealInfo",
    slots: { title: "setMealInfo_1" },
    scopedSlots: { customRender: "setMealInfo" },
  },
  {
    title: "购买方式",
    dataIndex: "buyMode",
    ellipsis: true,
    key: "buyMode",
  },
  {
    title: "工作人员",
    dataIndex: "contact",
    ellipsis: true,
    key: "contact",
    scopedSlots: { customRender: "contact" },
  },
  {
    title: "联系电话",
    dataIndex: "phone",
    ellipsis: true,
    key: "phone",
    scopedSlots: { customRender: "phone" },
  },
  {
    title: "操作",
    key: "option",
    dataIndex: "option",
    width: "150px",
    scopedSlots: { customRender: "option" },
  },
];
import editPayService from "./editPayService.vue";
import { payServiceApi } from "@/api/payService";
import { setKey } from "@/utils/utils.js";
export default {
  components: { editPayService },
  data() {
    return {
      columns,
      loading: false,
      serviceOption: [
        {
          label: "贸易端",
          value: "1",
        },
        {
          label: "游客端",
          value: "2",
        },
      ],
      serviceEnm: { 1: "贸易端", 2: "游客端" },
      data: [],
      config: {
        visible: false,
      },
    };
  },
  methods: {
    async getBaseData() {
      this.loading = true;
      try {
        let res = await payServiceApi.getAllService();
        this.data = setKey(res.data);
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    editFactory(record) {
      this.config.visible = true;
      this.config.data = record;
    },
  },
  mounted() {
    this.getBaseData();
  },
};
</script>
<style lang="scss" scoped>
.top-container {
  background: #fff;
  padding: 2.7rem 2.7rem 0 2.7rem;
  margin-bottom: 2rem;
}
.ant-table-wrapper {
  // margin-top: 20px;
  /deep/.ant-table-thead > tr > th {
    background-color: #ffe9db;
  }
}
</style>
